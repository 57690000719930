<aside [ngClass]="isToggled() ? 'visible' : ''">
  <div class="background" (click)="toggleSidebar()"></div>

  <nav class="sidenav d-none d-md-block" aria-label="rakendused">
    <ul>
      <li>
        <a [routerLink]="['/orders']" routerLinkActive="active" class="sidenav-link icon-works">Töötaotlused</a>
      </li>
      <li *ngxPermissionsOnly="'application.user-registry'">
        <a href="{{getConfigUrl('userregistry')}}" class="sidenav-link icon-users">Kasutajad</a>
      </li>
      <li *ngxPermissionsOnly="'application.tuumik'">
        <a href="{{getConfigUrl('tuumikbilling')}}" class="sidenav-link icon-euro">Kulud</a>
      </li>
      <li *ngxPermissionsOnly="'application.property-registry'">
        <a href="{{getConfigUrl('propertyregistry')}}" class="sidenav-link icon-objects">Kinnistud</a>
      </li>
      <li *ngxPermissionsOnly="'application.contract-registry'">
        <a href="{{getConfigUrl('lepingud')}}" class="sidenav-link icon-files">Lepingud</a>
      </li>
      <li *ngxPermissionsOnly="'application.rental-offers'">
        <a href="{{getConfigUrl('yyripakkumised')}}" class="sidenav-link icon-rentals">Üüripakkumised</a>
      </li>
      <li *ngxPermissionsOnly="[
      'instructions.access.client-instructions',
      'instructions.access.contractor-instructions',
      'instructions.access.rkas-instructions',
      'instructions.manage']">
        <a class="sidenav-link icon-manual" (click)="openInstructionModal()">Kasutusjuhendid</a>
      </li>
    </ul>
  </nav>

</aside>
