import {Injectable} from '@angular/core';
import {ConfigService} from '@app/config';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MaterializedViewType} from '@app/core/constants/materialized-view.type';
import {ActivityType} from '@app/core/constants/activity.type';
import {ActivityResponse} from '@app/core/models';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ActivityService {

    public constructor(
        private config: ConfigService,
        private httpClient: HttpClient
    ) {
    }

    public getState(type: ActivityType): Observable<ActivityResponse> {
        const path = this.config.getBackendUrl(`/activities/latest/${type}`);
        const options = {withCredentials: true};

        return this.httpClient.get<any>(path, options).pipe(map(ActivityResponse.from));
    }

    public updateMaterializedView(type: MaterializedViewType): Observable<ActivityResponse> {
        const path = this.config.getBackendUrl('/activities/update/materialized-view');
        const params = {view: type};
        const options = {params, withCredentials: true};

        return this.httpClient.post<any>(path, null, options).pipe(map(ActivityResponse.from));
    }

    public updateMaintenanceOrdersSetStatusApproved(): Observable<ActivityResponse> {
        const path = this.config.getBackendUrl('/activities/update/orders/maintenance/set-approved');
        const options = {withCredentials: true};

        return this.httpClient.put<any>(path, null, options).pipe(map(ActivityResponse.from));
    }
}
